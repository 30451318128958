<div class="bg-steel-100 text-center lg:text-left" *transloco="let t; read: 'footer'">
  <div class="container mx-auto grid gap-8 px-6 py-8 sm:grid-cols-2 sm:px-8 lg:grid-cols-4">
    <div class="">
      <img class="mx-auto lg:mx-0" [lazyLoad]="'/logos/logo-light.svg' | staticImageUrl" alt="RateMyAgent" width="165" height="48" />

      <dl class="mt-4">
        @if (salesNumber$ | async; as salesNumber) {
          <dt class="text-steel-20 mb-2 mr-2 lg:float-left">{{ t('contact.sales') }}</dt>
          <dd class="mb-2 text-white">{{ salesNumber }}</dd>
        }
        @if (supportNumber$ | async; as supportNumber) {
          <dt class="text-steel-20 clear-left mb-2 mr-2 lg:float-left">{{ t('contact.support') }}</dt>
          <dd class="mb-2 text-white">{{ supportNumber }}</dd>
        }
        @if (supportEmail$ | async; as supportEmail) {
          <dt class="text-steel-20 clear-left mr-2 lg:float-left">{{ t('contact.email') }}</dt>
          <dd class="mb-2 text-white">{{ supportEmail }}</dd>
        }
        @if (trainingWebinarUrl$ | async; as trainingWebinarUrl) {
          <dt class="text-steel-20 clear-left mr-2 lg:float-left">{{ t('contact.webinar.label') }}</dt>
          <dd>
            <a [href]="trainingWebinarUrl" target="_blank" rel="noopener">{{ t('contact.webinar.link') }}</a>
          </dd>
        }
        @if (hasIntercom$ | async) {
          <dt class="text-steel-20 clear-left mr-2 lg:float-left">{{ t('contact.intercom.label') }}</dt>
          <dd>
            <button (click)="openIntercom()" type="button">{{ t('contact.intercom.link') }}</button>
          </dd>
        }
      </dl>
    </div>

    <nav class="flex flex-col items-center lg:items-start">
      <div class="text-steel-20 mb-1 text-lg">{{ t('menu.company') }}</div>
      @if (aboutPage$ | async; as url) {
        <a class="mb-2 font-normal !no-underline" [href]="url" target="_blank" rel="noopener">
          {{ t('links.about') }}
        </a>
      }
      <a class="mb-2 font-normal !no-underline" href="https://ratemyagent.bamboohr.com/careers" target="_blank" rel="noopener">
        {{ 'footer.links.careers' | transloco }}
      </a>
      <a class="mb-2 font-normal !no-underline" routerLink="/privacy-policy"> {{ t('links.privacy') }}</a>
      <a class="mb-2 font-normal !no-underline" routerLink="/terms-and-conditions">{{ t('links.terms') }}</a>
      @if (hasCookiePolicy) {
        <button class="mb-2 inline font-normal !no-underline" (click)="showPrivacyBanner()" type="button">
          {{ t('links.cookie') }}
        </button>
      }
    </nav>

    <nav class="flex flex-col items-center lg:items-start">
      <div class="text-steel-20 mb-1 text-lg">{{ t('menu.help') }}</div>
      <a class="mb-2 font-normal !no-underline" routerLink="/blog"> {{ t('links.blog') }}</a>
      @if (trainingWebinarUrl$ | async; as trainingWebinarUrl) {
        <a class="mb-2 font-normal !no-underline" [href]="trainingWebinarUrl" target="_blank" rel="noopener">
          {{ t('links.webinars') }}
        </a>
      }
      @if (contactUsUrl$ | async; as contactUsUrl) {
        <a class="mb-2 font-normal !no-underline" [href]="contactUsUrl" target="_blank" rel="noopener">
          {{ t('links.contact-us') }}
        </a>
      }
    </nav>

    <nav class="flex flex-col items-center lg:items-start">
      <div class="text-steel-20 mb-1 text-lg">{{ t('menu.product') }}</div>
      <a class="mb-2 font-normal !no-underline" routerLink="/features">{{ t('links.features') }}</a>

      <a class="mb-2 font-normal !no-underline" routerLink="/subscriptions"> {{ t('links.subscriptions') }}</a>
      @if (awardsPage$ | async; as url) {
        <a class="mb-2 font-normal !no-underline" [href]="url">
          {{ t('links.awards') }}
        </a>
      }
      @if (showBrandLink$ | async) {
        <a class="mb-2 font-normal !no-underline" href="/brand">
          {{ t('links.brand') }}
        </a>
      }
      @if (showFaqLink$ | async) {
        <a class="mb-2 font-normal !no-underline" href="/faq">{{ t('links.faq') }}</a>
      }
      @if (showMlsLink$ | async) {
        <a class="mb-2 font-normal !no-underline" routerLink="/mls/licensing">{{ t('links.licensing') }}</a>
      }
    </nav>
  </div>
</div>

<div class="bg-steel-90 text-steel-20 text-center lg:text-left">
  @if (locationLinks$ | async; as locationLinks) {
    <div class="container mx-auto grid gap-8 px-6 py-8 sm:grid-cols-2 sm:px-8" [class.lg:grid-cols-4]="locationLinks.hasManyFooterLinks">
      <rma-footer-location-links
        [locationLinks]="locationLinks.links"
        [isAgent]="true"
        [twoColumns]="!locationLinks.hasManyFooterLinks"
        verticalType="Sales" />
      <rma-footer-location-links
        [locationLinks]="locationLinks.links"
        [twoColumns]="!locationLinks.hasManyFooterLinks"
        verticalType="Sales" />
      @if (hasLeasing$ | async) {
        <rma-footer-location-links [locationLinks]="locationLinks.links" verticalType="Leasing" />
      }
      @if (hasFinance$ | async) {
        <rma-footer-location-links [locationLinks]="locationLinks.links" [isAgent]="true" verticalType="Finance" />
      }
    </div>
  }
</div>

<div class="bg-steel-80 text-white">
  <div
    class="align-center container mx-auto flex flex-wrap items-center justify-center px-6 pb-4 pt-2 sm:flex-row-reverse sm:flex-nowrap sm:justify-between sm:px-8 sm:py-2">
    <nav class="mb-2 grow text-center sm:mb-0 sm:flex-initial">
      @for (social of socials; track social) {
        <a
          class="mx-2"
          [href]="social.href"
          [attr.aria-label]="social.label"
          rma-icon-button
          target="_blank"
          rel="noopener"
          angulartics2On="click">
          <rma-icon [glyph]="social.icon" />
        </a>
      }
    </nav>
    <span>
      &copy; {{ currentYear }} RateMyAgent •
      <a class="font-normal text-white hover:underline" routerLink="/sitemap"> {{ 'footer.links.sitemap' | transloco }}</a>
    </span>
  </div>
</div>
