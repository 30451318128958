@if (showHeader$ | async) {
  <rma-header [basicHeader]="(basicHeader$ | async) ?? false" (toggle)="toggleSideNav()" />
}

<section class="rma-content">
  <div class="rma-loader" [class.visible]="isLoading" [class.invisible]="!isLoading">
    @if (isLoading) {
      <mat-spinner />
    }
  </div>

  <router-outlet />

  @if (showFooter$ | async) {
    <rma-footer />
  }
</section>
