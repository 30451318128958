import { HttpErrorResponse, HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { catchError, EMPTY, throwError } from 'rxjs';

export const abortErrorInterceptor: HttpInterceptorFn = (req: HttpRequest<unknown>, next: HttpHandlerFn) => {
  return next(req).pipe(
    catchError((error: HttpErrorResponse) => {
      if (error.message.startsWith('Fetch is aborted')) {
        return EMPTY;
      }

      return throwError(() => error);
    }),
  );
};
