import { Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CurrentUserService } from '@rma/accounts/user';
import { Environment } from '@rma/generic/util/environment';
import { nonNullable } from '@rma/generic/util/operators/combined';
import { map } from 'rxjs/operators';
import { TrackJS } from 'trackjs';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class TrackJsService {
  constructor(
    private readonly userService: CurrentUserService,
    private readonly environment: Environment,
  ) {}

  initialise() {
    if (!TrackJS.isInstalled()) {
      return;
    }

    const { language, version } = this.environment;

    TrackJS.addMetadata('locale', language.locale);

    TrackJS.configure({ version });
    this.userService.currentUser$
      .pipe(
        nonNullable(),
        map(({ userId }) => ({ version, userId })),
        untilDestroyed(this),
      )
      .subscribe((config) => {
        TrackJS.configure(config);
      });
  }
}
