import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@jsverse/transloco';
import { WA_WINDOW } from '@ng-web-apis/common';
import { RmaIconComponent } from '@rma/generic/ui/icon';
import { Environment, StaticImageUrlPipe } from '@rma/generic/util/environment';
import { FeatureFlags, FeatureProviderService } from '@rma/generic/util/feature-flags';
import { IntercomService } from '@rma/generic/util/tracking/intercom';
import { CurrentSubscriptionService } from '@rma/subscriptions/public-api/da-subscriptions';
import { Angulartics2Module } from 'angulartics2';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { SocialLink } from '../data-access/footer.const';
import { FooterService } from '../data-access/footer.service';
import { LocationFooterLink } from '../data-access/layout.service';
import { FooterLocationLinksComponent } from '../ui-footer-location-links/footer-location-links.component';
interface AircallPhoneNumbers {
  Sales: string;
  Support: string;
}

interface ShowPrivactWindow extends Window {
  sp?: {
    showPrivacyBanner: () => void;
  };
  securePrivacy?: unknown;
}

@Component({
  selector: 'rma-footer',
  templateUrl: './footer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    Angulartics2Module,
    AsyncPipe,
    FooterLocationLinksComponent,
    LazyLoadImageModule,
    MatButtonModule,
    RmaIconComponent,
    RouterModule,
    StaticImageUrlPipe,
    TranslocoModule,
  ],
})
export class FooterComponent {
  @HostBinding('class.is-dark-theme')
  public readonly classes = true;

  protected readonly aboutPage$: Observable<string | null>;
  protected readonly awardsPage$: Observable<string | null>;
  protected readonly locationLinks$: Observable<{ links: LocationFooterLink[]; hasManyFooterLinks: boolean }>;

  protected readonly hasFinance$: Observable<boolean>;
  protected readonly hasIntercom$: Observable<boolean>;
  protected readonly hasLeasing$: Observable<boolean>;

  protected readonly contactUsUrl$: Observable<string>;
  protected readonly salesNumber$: Observable<string | undefined>;
  protected readonly supportEmail$: Observable<string | undefined>;
  protected readonly supportNumber$: Observable<string | undefined>;

  protected readonly showBrandLink$: Observable<boolean>;
  protected readonly showFaqLink$: Observable<boolean>;
  protected readonly showMlsLink$: Observable<boolean>;
  protected readonly trainingWebinarUrl$: Observable<string | null>;

  protected readonly currentYear: number = new Date().getFullYear();
  protected readonly hasCookiePolicy: boolean;
  protected readonly socials: SocialLink[];

  constructor(
    private readonly featureProviderService: FeatureProviderService,
    private readonly currentSubscriptionService: CurrentSubscriptionService,
    private readonly footerService: FooterService,
    private readonly intercomService: IntercomService,
    private readonly environment: Environment,
    @Inject(WA_WINDOW) private readonly window: ShowPrivactWindow,
  ) {
    const displayEmailSupport$ = this.getShowForLoggedInOrFeatureFlag(FeatureFlags.FooterShowSupportEmailToUnsubscribedUsers);
    const displayPhoneSupport$ = this.getShowForLoggedInOrFeatureFlag(FeatureFlags.FooterShowSupportNumberToUnsubscribedUsers);
    const locationLinks$ = this.footerService.getLocationLinks();
    const phoneNumbers$ = this.featureProviderService.getFeature$<AircallPhoneNumbers | null>(FeatureFlags.AircallPhoneNumbers, null);
    const supportEmail$ = this.featureProviderService.getFeature$(FeatureFlags.SupportEmail, undefined);
    const trainingWebinarUrlConfig$ = this.featureProviderService.getFeature$<string | null>(FeatureFlags.TrainingWebinarUrl, null);

    this.hasCookiePolicy = !!this.environment.securePrivacyId;
    this.socials = this.footerService.getSocialLinks();

    this.aboutPage$ = this.featureProviderService.getFeature$(FeatureFlags.AboutPage, null);
    this.awardsPage$ = this.featureProviderService.getFeature$(FeatureFlags.FooterAwardsUrl, null);
    this.hasFinance$ = this.featureProviderService.getFeature$(FeatureFlags.EnableFinance, false);
    this.hasIntercom$ = this.intercomService.isLoaded$;
    this.hasLeasing$ = this.featureProviderService.getFeature$(FeatureFlags.EnableLeasing, false);
    this.showBrandLink$ = this.featureProviderService.getFeature$(FeatureFlags.BrandFooterLink, false);
    this.showFaqLink$ = this.featureProviderService.getFeature$(FeatureFlags.FaqFooterLink, false);
    this.showMlsLink$ = this.featureProviderService.getFeature$(FeatureFlags.EnableMlsFooterLink, false);
    this.contactUsUrl$ = this.featureProviderService.getFeature$(FeatureFlags.ContactUsUrl, '');

    const hasManyFooterLinks$ = combineLatest([this.hasFinance$, this.hasLeasing$]).pipe(map(([x, y]) => x || y));
    this.locationLinks$ = combineLatest([locationLinks$, hasManyFooterLinks$]).pipe(
      map(([links, hasManyFooterLinks]) => ({ links, hasManyFooterLinks })),
    );

    this.salesNumber$ = phoneNumbers$.pipe(map((x) => x?.Sales));

    this.supportNumber$ = combineLatest([phoneNumbers$, displayPhoneSupport$]).pipe(
      map(([phoneNos, display]) => (display ? phoneNos?.Support : undefined)),
    );

    this.supportEmail$ = combineLatest([supportEmail$, displayEmailSupport$]).pipe(
      map(([email, display]) => (display ? email : undefined)),
    );

    this.trainingWebinarUrl$ = combineLatest([trainingWebinarUrlConfig$, this.currentSubscriptionService.currentSubscription$]).pipe(
      map(([url, currentSub]) => (!(currentSub?.isFree ?? true) ? url : null)),
    );
  }

  public showPrivacyBanner(): void {
    if (this.window.sp) {
      this.window.sp.showPrivacyBanner();
    }
  }

  public openIntercom(): void {
    this.intercomService.showMessenger();
  }

  private getShowForLoggedInOrFeatureFlag(flag: FeatureFlags) {
    const showSupportNumberToUnsubscribedUsers$ = this.featureProviderService.getFeature$(flag, true);
    return combineLatest([showSupportNumberToUnsubscribedUsers$, this.currentSubscriptionService.currentSubscription$]).pipe(
      map(([showForFree, currentSub]) => showForFree || !(currentSub?.isFree ?? true)),
    );
  }
}
