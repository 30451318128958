import { ImageLoaderConfig } from '@angular/common';
import { Environment } from '@rma/generic/util/environment';

export function getCdnUrl(env: Environment, config: ImageLoaderConfig): string | null {
  return cdnUrlWithDomainProvided(env, config);
}

function cdnUrlWithDomainProvided(env: Environment, config: ImageLoaderConfig): string | null {
  const { cdnUrl } = env.assets;
  const urlContainsCdn = config.src?.includes(cdnUrl);
  const hostIsCdn = config?.loaderParams?.['host'] === 'RmaCdn';

  if (urlContainsCdn) {
    return updateCdnUrlWithOptimisations(env, config);
  }
  if (hostIsCdn) {
    return createCdnUrl(env, config);
  }

  return null;
}

function createCdnUrl(env: Environment, config: ImageLoaderConfig) {
  const { cloudinaryBase, cloudinarySuffix } = env.assets;
  const transformations = config?.loaderParams?.['transformations'] ?? 'q_auto:eco,f_auto';
  const cleanedPath = config.src;

  return `${cloudinaryBase}${transformations}${cloudinarySuffix}/${cleanedPath}`;
}

function updateCdnUrlWithOptimisations(env: Environment, config: ImageLoaderConfig) {
  const { cdnUrl, cloudinaryBase, cloudinarySuffix } = env.assets;
  const transformations = config?.loaderParams?.['transformations'] ?? 'q_auto:eco,f_auto';

  return config.src.replace(cdnUrl, `${cloudinaryBase}${transformations}${cloudinarySuffix}`);
}
