import { APP_INITIALIZER, EnvironmentProviders, makeEnvironmentProviders, NgZone } from '@angular/core';
import { Environment, PlatformService } from '@rma/generic/util/environment';
import { HeapScriptService } from './heap-script.service';

function initialiseHeap(
  environment: Environment,
  platformService: PlatformService,
  heapScriptService: HeapScriptService,
  zone: NgZone,
): () => void {
  return () => {
    if (platformService.isPlatformBrowser && environment.tracking.heapAppId) {
      zone.runOutsideAngular(() => heapScriptService.initialiseHeap());
    }
  };
}

export function provideRmaHeap(): EnvironmentProviders {
  return makeEnvironmentProviders([
    {
      provide: APP_INITIALIZER,
      useFactory: initialiseHeap,
      deps: [Environment, PlatformService, HeapScriptService, NgZone],
      multi: true,
    },
  ]);
}
